import React from "react";
import team001 from "../../images/t2.jpg";
// import { Link } from 'gatsby-plugin-modal-routing'

import ConditionalLayout from "../../components/ConditionalLayout";
// import SEO from "../../components/seo"

const SecondPage = () => (
  <ConditionalLayout>
    <h3 className="title-3">Anna Godfrey</h3>
    <h4 className="">Director</h4>
    <div className="card">
      <div className="card-body">
        <div className="team-img">
          <img alt="About team" className="img-fluid" src={team001} />
        </div>
        <p>
          Anna has a reputation for getting ‘Innovation’ DONE! She has
          infectious enthusiasm that inspires people into action and she loves
          nothing more than creating amazing new ideas that deliver
          transformational growth.{" "}
        </p>
        <p>
          As Director of The Switcher, Anna drives growth opportunities and
          fresh new thinking for many organisations and help leaders turn
          complex issues into simple strategies. She’s also an Innovation
          Facilitator at Raw Innovation, which means she helps INSPIRE
          Innovation and DO REAL innovation.{" "}
        </p>
        <p>
          Anna is the ex Director of Innovation at McDonald’s. She’s a highly
          successful innovator and executor, having developed innovative
          concepts and product development on a local and global level. She led
          their Development Process, sold growth strategies to tough
          stakeholders and then implemented them into over 1000 restaurants in
          Australia & New Zealand.{" "}
        </p>
        <p>
          Her love for business and big ideas began in the Advertising Industry
          where she mastered the art of creating and selling big ideas. In her
          spare time, you’ll find her mentoring and coaching women in business
          and is a FIFO working Mum of three who juggles her passion for
          business with typical family life. It’s never dull, but she wouldn’t
          have it any other way!
        </p>
      </div>
    </div>
  </ConditionalLayout>
);

export default SecondPage;
